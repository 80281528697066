.footer {
  background-color: midnightblue;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.footer-section {
  flex: 1;
  margin: 0 10px;
}

.contact,
.middle,
.signoff {
  flex: 1;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin: 5px 0;
}
